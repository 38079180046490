import { Box, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { countEmail } from "./firebase/email";

const steps = [
    { title: 'Tháng 4', description: 'Đăng Ký Trước' },
    { title: 'Tháng 6', description: 'Close Beta' },
    { title: '???', description: 'Open Beta' },
  ]
  
  export default function Process() {
    const { activeStep } = useSteps({
      index: 1,
      count: steps.length,
    })
  
    return (
      <Stepper index={activeStep} orientation='vertical' height={"50%"} gap='0'>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
  
            <Box flexShrink='0' color={"black"}>
              <StepTitle>[{step.title}] <b>{step.description}</b></StepTitle>
              {/* <StepDescription>{step.description}</StepDescription> */}

            </Box>
  
            <StepSeparator />
          
          </Step>
        ))}
      </Stepper>
    )
  }
  
