import React from 'react';
import './App.css';
import { Box, Center, Flex } from '@chakra-ui/react';

function Game() {

  return (
    <div style={{ width: '100%', height: '100vh', border: 'none' }}>
      <iframe
        src="/web/index.html"
        title="Web Page"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
}

export default Game;