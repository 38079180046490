import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  InputGroup,
  InputLeftAddon,
  FormHelperText,
} from '@chakra-ui/react';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { Image } from '@chakra-ui/react'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';


interface RegisterResponse {
  status: string,
  error: string,
  data: {
    token: number;
    username: string;
  }
}
const TEST_SITE_KEY = "6LfgcvApAAAAANNmDL-SWNnR-iw7HOfbXghQUEF6";
const DELAY = 1500;
const SimpleCard: React.FC = () => {
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [captcha, setCaptcha] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [captchaImg, setCapchaImg] = useState("https://account.ohgame.io.vn/captcha.php");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch('https://account.ohgame.io.vn/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, captcha, username }),
      });

      const data: RegisterResponse = await response.json();
      setCapchaImg("https://account.ohgame.io.vn/captcha.php?time=" + new Date())
      if (response.ok) {

        if (data.status === "error") {

          Store.addNotification({
            title: "Thất bại!",
            message: data.error,
            type: 'warning',
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {

              duration: 5000,
              onScreen: true
            }
          })
        } else if (data.data.token) {
          setIsDone(true);
          setErrorMessage("Tạo thành công! Chú ý không sài gmail ảo vì hệ thống sẽ gửi mã xác thực nếu không xác thực có thể bị xoá tài khoản");
          Store.addNotification({
            title: "Thành công!",
            message: "Đăng Ký Thành Công",
            type: 'success',
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            },
            onRemoval: (function () {
              navigate('../');
            })
          })

        }
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setErrorMessage("Lỗi");
    }
  };

  return (
    <Flex
      minH={'82vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('#d7fdfa', '#d7fdfa')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        {/* <Stack align={'center'}>
          <Heading fontSize={'4xl'} color={"back"}>Đăng ký tài khoản</Heading>
        </Stack> */}
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="username">
                <FormLabel>Tên Tài Khoản</FormLabel>
                <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                <FormHelperText>Chữ cái tiếng Anh và không chứa ký tự đặc biệt, dấu cách.</FormHelperText>
              </FormControl>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Mật Khẩu</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </FormControl>
              <Stack >
                <InputGroup height={'50px'}>
                  <Image src={captchaImg} />
                  <Input type="text" value={captcha} height={'50px'} onChange={(e) => setCaptcha(e.target.value)} required />
                </InputGroup>
              </Stack>

              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox defaultChecked>Nhấn nút Đăng Ký đồng nghĩa với việc bạn đồng ý <Link color={'blue.400'}>Điều Khoản</Link> của chúng tôi</Checkbox>
                </Stack>
                {!isDone ? (<Button

                  type="submit"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Đăng Ký
                </Button>) : (<Button


                  bg={"gray"}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Đăng Ký
                </Button>)}

              </Stack>
            </Stack>
          </form>
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
        </Box>
      </Stack>
    </Flex>
  );
}

export default SimpleCard;
