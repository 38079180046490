'use client'

import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Icon,
    IconButton,
    createIcon,
    IconProps,
    useColorModeValue,
    AspectRatio,
    Center,
} from '@chakra-ui/react'
import video from '../asset/bg.mp4'
import CardWithIllustration from './PreRegister'
import FacebookButton from './FacebookButton'
import Gift from './Gift'
import Process from './Process'
export default function CallToActionWithVideo() {
    return (
        <Box backgroundColor={"#d7fdfa"} >
            <Center> <Stack


               
                direction={{ base: 'column', md: 'row' }}>


                <Box
                    background={"#94c0bd"}
                    position={'relative'}
                  
                    width={{ base: '90vw', md: '48vw' }}  
                    rounded={'2xl'}
                    boxShadow={'2xl'}



                    padding={2}
                    borderRadius={'xl'}
                    borderBottom={5}
                    borderRight={7}
                    borderStyle={'solid'}
                    borderColor={"#60a4c3"}
                > 
                {/* <Process></Process> */}
              <FacebookButton></FacebookButton>
             <Process></Process>

             <Center paddingTop={"6"}> <Text textAlign={"center"}>Chuỗi sự kiện mừng Open Beta sẽ được diễn ra sau khi Close Beta với nhiều phần quà HIỆN VẬT hấp dẫn! Cùng chờ đón nào.</Text></Center>
            
                </Box>
                <CardWithIllustration></CardWithIllustration>

            </Stack></Center>

        </Box>
    )
}


const Blob = (props: IconProps) => {
    return (
        <></>
    )
}