
import { FaFacebook } from 'react-icons/fa'
import { Button, Center, Text } from '@chakra-ui/react'

export default function FacebookButton() {
  const handleFacebookButtonClick = () => {
    // Replace 'https://facebook.com/your-page-url' with the URL you want to open
    const facebookPageUrl = 'https://www.facebook.com/profile.php?id=61558481580767';
    window.open(facebookPageUrl, '_blank');
  };
  return (
    <Center p={8}>
      <Button w={'full'} maxW={'md'} colorScheme={'facebook'} leftIcon={<FaFacebook />} onClick={handleFacebookButtonClick}>
        <Center>
          <Text>MyFish Mobile</Text>
        </Center>
      </Button>
    </Center>
  )
}