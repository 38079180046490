// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyD7_yioqNzoOy4HpLS6NZ_W6DSU5-YFFNI",
  authDomain: "myfish-landing.firebaseapp.com",
  projectId: "myfish-landing",
  storageBucket: "myfish-landing.appspot.com",
  messagingSenderId: "311256350827",
  appId: "1:311256350827:web:f5f2743664a1b2221e312e",
  measurementId: "G-61B9KK0D2W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// export const realtimeDB =  getDatabase();
 const db =getFirestore(app);
export { db };