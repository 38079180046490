
import {
  Image,
  Box,
  Button,
  HStack,
  Center,
  Spacer,
} from '@chakra-ui/react'
import logo from "../asset/logo.png"
import { useNavigate } from 'react-router-dom';

export default function WithSubnavigation() {
  const navigate = useNavigate();
  return (
  <Center py={"1"} background={"#d7fdfa"}>
      <HStack width={"99%"} zIndex={2}  
        bg={'orange.700'}
       padding={"2"}
        borderRadius={10}
        borderBottom={5}
        borderLeft={5}
        borderStyle={'solid'}
        borderColor={'orange.900'}
        
      >

          <Image
            src={logo}
            height={"50"}
            onClick={()=>{
              navigate('/');
            }}
          />


          <DesktopNav />

     
          <Spacer />
          <Button 
         onClick={()=>{
          navigate('/game');
         }}
            as={'a'}
            fontSize={'sm'}
            fontWeight={900}
            borderRadius={10}
            color={'white'}
            bg={'green.500'}
            href={'#'}
            _hover={{
              bg: 'yellow.700',
            }}>
            Web Game
          </Button>

          <Button 
         onClick={()=>{
          navigate('/payment');
         }}
            as={'a'}
            fontSize={'sm'}
            fontWeight={900}
            borderRadius={10}
            color={'white'}
            bg={'yellow.500'}
            href={'#'}
            _hover={{
              bg: 'yellow.700',
            }}>
            Thanh toán
          </Button>
          {localStorage.token?(  <Button 
         onClick={()=>{
         localStorage.removeItem("token");
         window.location.reload();
         }}
            as={'a'}
            fontSize={'sm'}
            fontWeight={900}
            borderRadius={10}
            color={'white'}
            bg={'red'}
            href={'#'}
            _hover={{
              bg: 'yellow.700',
            }}>
            Đăng Xuất
          </Button>):(  <Button 
         onClick={()=>{
          navigate('/dangky');
         }}
            as={'a'}
            fontSize={'sm'}
            fontWeight={900}
            borderRadius={10}
            color={'white'}
            bg={'yellow.500'}
            href={'#'}
            _hover={{
              bg: 'yellow.700',
            }}>
            Đăng Ký
          </Button>)}
        
        </HStack>
      
  </Center>

  )
}

const DesktopNav = () => {

  return (



    <HStack as={'nav'} display={{ base: 'none', md: 'flex' }} px={'4'} >


      {NAV_ITEMS.map((navItem) => (
        <Box
          as="a"
          px={2}
          py={1}
          rounded={'md'}
          _hover={{
            textDecoration: 'none',
          }}
          href={'#'} fontWeight={"700"}>
          {navItem.label}
        </Box>
      ))}
    </HStack>


  )
}



interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Sự Kiện',
  //   href: '#',
  // },
  // {
  //   label: 'Cộng Đồng',
  //   href: '#',
  // },
  // {
  //   label: 'Nạp Thẻ',
  //   href: '#',
  // },
  // {
  //   label: 'Hỗ trợ',
  //   href: '#',
  // },
]