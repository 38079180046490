import { addDoc, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { collection, getDocs }  from "firebase/firestore";

export const findAll = async () => {
    // Query a reference to a subcollection
    const querySnapshot = await getDocs(collection(db, "mail"));
    querySnapshot.forEach((doc: { id: any; data: () => any; }) => {
      
    });
}

export const countEmail = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "mail"));
        return querySnapshot.size+50;
    } catch (error) {
        return 99;
        throw error;
    }
}
const fetchUserData = async (email:string) => {
    try {
    //   const url = 'http://14.225.192.108:3001/user/mail';
    //   const requestData = {
    //     mail: email
    //   };
    //   await fetch(url, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(requestData)
    //   });
    } catch (error) {
     
    }
  };
export const addMail = async (data: string) => {
    try {
        // Check if the mail already exists
        const mailExists = await checkMailExists(data);
        if (!mailExists) {
            // If the mail doesn't exist, add it
            const docRef = await addDoc(collection(db, "mail"), {
                mail: data
            });
          
            await fetchUserData(data)
            alert("Đăng ký thành công")
        } else {
           
        
            alert("Email đã tồn tại trên hệ thống")
        }
    } catch (error) {
       
        throw error;
    }
};

// Function to check if the mail already exists
const checkMailExists = async (data: string) => {
    try {
        const q =  query(collection(db, "mail"),where("mail", "==", data));
        const querySnapshot = await getDocs(q);
       
        return querySnapshot.size>0
    } catch (error) {
      
        throw error;
    }
};

