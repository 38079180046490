'use client'

import {
    Box,
    Stack,
    Text,
    VStack,
    Button,
    Image
} from '@chakra-ui/react'

import Bachtuot from '../asset/bachtuot.png'
import Camap from '../asset/camap.png'
import NguChien from '../asset/nguchien.png'
import TromCa from '../asset/tromca.png'
interface Props {
    children: React.ReactNode
}

function PriceWrapper(props: Props) {
    const { children } = props

    return (
        <Box
            mb={4}
            shadow="base"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderRadius={'xl'}>
            {children}
        </Box>
    )
}

export default function ThreeTierPricing() {

    return (
        <Box backgroundColor={"#d7fdfa"} py={2} padding={25}>
            {/* <VStack spacing={2} textAlign="center" >
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Start with 14-day free trial. No credit card needed. Cancel at anytime.
        </Text>
      </VStack> */}
            <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}>



                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={'#2b99d4'}
                                px={3}
                                py={1}
                                fontSize="sm"
                                fontWeight="1000"
                                rounded="xl">
                                Nuôi cá
                            </Text>
                        </Box>

                        <VStack
                            backgroundColor={"#94c0bd"}
                            py={4}
                            borderRadius={'xl'}
                            borderBottom={5}
                            borderRight={7}
                            borderStyle={'solid'}
                            borderColor={"#60a4c3"}>
                            <Text textAlign="start" px={4} >


                            Khám phá thế giới dưới đáy hồ cá với chức năng nuôi cá! Mua các loại cá đa dạng, trang trí hồ cá theo phong cách của bạn, bán cá để kiếm lợi nhuận và chăm sóc cá mỗi ngày. 
                            </Text>
                            <Box height={"90px"}>

                            </Box>
                            <Box position="absolute"
                                bottom="-35px"
                                left="-20px">
                                <Image
                                    src={Bachtuot}
                                    height={"150"}
                                />
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={'#2b99d4'}
                                px={3}
                                py={1}
                                fontSize="sm"
                                fontWeight="1000"
                                rounded="xl">
                                Lai cá
                            </Text>
                        </Box>

                        <VStack
                            backgroundColor={"#94c0bd"}
                            py={4}
                            borderRadius={'xl'}
                            borderBottom={5}
                            borderRight={7}
                            borderStyle={'solid'}
                            borderColor={"#60a4c3"}>
                            <Text textAlign="start" px={4} >

                            Bạn có thể kết hợp các thuộc tính khác nhau để tạo ra những chú cá độc đáo. Hãy thử lai tạo và khám phá những kỹ thuật mới để tạo ra những chú cá mạnh mẽ và đáng yêu hơn bao giờ hết! 

                            </Text>
                            <Box height={"90px"}>

                            </Box>
                            <Box position="absolute"
                                bottom="-35px"
                                left="-20px">
                                <Image
                                    src={Camap}
                                    height={"150"}
                                />
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={'#2b99d4'}
                                px={3}
                                py={1}
                                fontSize="sm"
                                fontWeight="1000"
                                rounded="xl">
                                TRỘM
                            </Text>
                        </Box>

                        <VStack
                            backgroundColor={"#94c0bd"}
                            py={4}
                            borderRadius={'xl'}
                            borderBottom={5}
                            borderRight={7}
                            borderStyle={'solid'}
                            borderColor={"#60a4c3"}>
                            <Text textAlign="start" px={4} >


                            Vào vai một kẻ trộm tài ba, người chơi không chỉ có thể thực hiện các vụ trộm tiền vàng của bạn bè mà còn có thể thực hiện những hành động thú vị hơn để tạo ra sự hỗn loạn và khó chịu.
                            </Text>
                            <Box height={"90px"}>

                            </Box>
                            <Box position="absolute"
                                bottom="-35px"
                                left="40px">
                                <Image
                                    src={TromCa}
                                    height={"150"}
                                />
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={'#2b99d4'}
                                px={3}
                                py={1}
                                fontSize='sm'
                                fontWeight="1000"
                                rounded="xl">
                                NGƯ CHIẾN
                            </Text>
                        </Box>

                        <VStack
                            backgroundColor={"#94c0bd"}
                            py={4}
                            borderRadius={'xl'}
                            borderBottom={5}
                            borderRight={7}
                            borderStyle={'solid'}
                            borderColor={"#60a4c3"}>
                            <Text textAlign="start" px={4} >


                            Người chơi sẽ bước vào một thế giới nơi các chú cá chiến mạnh mẽ tụ họp và chuẩn bị cho những cuộc đối đầu đỉnh cao. Mỗi trận đấu không chỉ là một thử thách về chiến lược mà còn là cơ hội để thể hiện sức mạnh của đội hình của mình.

                            </Text>
                            <Box height={"90px"}>

                            </Box>
                            <Box position="absolute"
                                bottom="-15px"
                                left="-20px"
                                >
                                <Image
                                    src={NguChien}
                                    height={"100"}
                                />
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
            </Stack>
        </Box>
    )
}