import { Box, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { countEmail } from "./firebase/email";
function getIndexByTitle(value:number):number {
  // Duyệt qua mảng steps từ cuối về đầu để lấy ra index đầu tiên thỏa mãn điều kiện
  for (let i = steps.length - 1; i >= 0; i--) {
    if (value >= steps[i].title) {
      return i + 1; // Trả về index tương ứng, tăng thêm 1 vì index bắt đầu từ 0
    }
  }
  return 0; // Trả về 0 nếu không có index nào thỏa mãn
}

const steps = [
  { title: 0, description: '500 Kim cương' },
  { title: 100, description: '[Gói Sơ Cấp] Giá trị lên tới 1K Kim cương' },
  { title: 500, description: '[Gói Trung Hoa] Giá trị lên tới 5K Kim cương' },
  { title: 1000, description: '' }
]
  export default function Gift() {
    const [count,setCount]=useState(0);
    useEffect(() => {
       
      const fetchCount = async () => {
          try {
              const result = await countEmail();
              setCount(result);
              setActiveStep(getIndexByTitle(result))
          } catch (error) {
             
          }
      };

      fetchCount();
  }, []);
      const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
      })
    
      const activeStepText = steps[activeStep].description
    
      return (
        <Stack color={"black"}>
          <Stepper size='sm' index={activeStep} gap='0'>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} />
                </StepIndicator>
                <StepTitle>{step.title} lượt</StepTitle>
              </Step>
              
            ))
            }
          </Stepper>
        <b color="black">{activeStepText}</b>
        </Stack>
      )
    }
    