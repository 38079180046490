import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, BrowserRouter, Routes } from 'react-router-dom';
import DangKy from './DangKy';
import Payment from './payment';
import Game from './Game';
import Payment2 from './payment2';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route index element={<App />} />
            <Route path="dangky" element={<DangKy />} />
            <Route path="payment" element={<Payment2 />} />
            <Route path="game" element={<Game />} />
          </Route>
        </Routes>
      </BrowserRouter>



    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
