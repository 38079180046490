import React from 'react';
import './App.css';
import { Box, Center, Flex } from '@chakra-ui/react';
import WithSubnavigation from './element/WithSubnavigation';

import bg from './asset/bg_1.png'
import ThreeTierPricing from './element/ThreeTierPricing';
import CallToActionWithVideo from './element/CallToActionWithVideo';
import SmallWithSocial from './element/footer';
import SimpleCard from './element/dangky';
import { ReactNotifications } from 'react-notifications-component'

function DangKy() {

  return (
    <Box>
        <ReactNotifications />
        <WithSubnavigation></WithSubnavigation>
     <SimpleCard></SimpleCard>
     
    <SmallWithSocial></SmallWithSocial>
    </Box>
  );
}

export default DangKy;