import React from 'react';
import './App.css';
import { Box, Center, Flex } from '@chakra-ui/react';
import WithSubnavigation from './element/WithSubnavigation';

import bg from './asset/bg_1.png'
import ThreeTierPricing from './element/ThreeTierPricing';
import CallToActionWithVideo from './element/CallToActionWithVideo';
import SmallWithSocial from './element/footer';

function App() {

  return (
    <Box>
        <WithSubnavigation></WithSubnavigation>
     
      <CallToActionWithVideo></CallToActionWithVideo>
      <ThreeTierPricing></ThreeTierPricing>
    <SmallWithSocial></SmallWithSocial>
    </Box>
  );
}

export default App;